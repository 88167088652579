export const API_CONFIG = {
    BASE_URL: 'https://erp.rajdeep.in/rajdeeperp', 
    AUTH: {
      // USERNAME: 'Ameet',
      // PASSWORD: '1',
      USERNAME: 'APIUser',
      PASSWORD: 'EPiQn5QETv6mU4',
    },
  };
  
  export const ENDPOINTS = {
    INVOICE: '/org.openbravo.service.json.jsonrest/Invoice',
  };
  
  // "proxy": "https://erp.murrplastik.in",
  // "proxy": "https://erp.rajdeep.in",
  // USERNAME: 'APIUser',
      // PASSWORD: 'EPiQn5QETv6mU4',

  // https://erp.murrplastik.in/openbravo/org.openbravo.service.json.jsonrest/Invoice?_where=invoiceDate >='2024-09-24' and invoiceDate <='2024-09-24' and documentAction='CO'&_selectedProperties=organization,businessPartner,summedLineAmount,grandTotalAmount,userContact