import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { fetchChartData } from '../../services/api';
import { Bar, Doughnut, Line, Pie, PolarArea } from 'react-chartjs-2';
import DatePicker from './DatePicker'
import FilterControls from './FilterControls';
import '../../styles.css';

ChartJS.register(
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Tooltip,
  Legend
);

const BarChart = () => {
  const [chartData, setChartData] = useState(null);
  const [error, setError] = useState(null);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [xAxisFilter, setXAxisFilter] = useState('organization');
  const [yAxisFilter, setYAxisFilter] = useState('grandTotalAmount');
  const [chartType, setChartType] = useState('Bar');
  const [topCount, setTopCount] = useState(10);

  const roundAmount = (amount, decimalPlaces = 0) => {
    return amount ? amount.toFixed(decimalPlaces) : '0.00';
  };

  // Get color palette for the chart
  const getColorPalette = (count) => {
    const colors = [
      '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
      '#FF4D4D', '#4D79FF', '#00CC99', '#FFB3B3',
    ];
    return Array.from({ length: count }, (_, i) => colors[i % colors.length]);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const fromDateFormatted = fromDate.toISOString().split('T')[0];
        const toDateFormatted = toDate.toISOString().split('T')[0];
        const data = await fetchChartData(fromDateFormatted, toDateFormatted);

        // Filter out entries with both amounts as 0
        const filteredData = data.filter(
          (item) =>
            (item.grandTotalAmount && item.grandTotalAmount !== 0) ||
            (item.summedLineAmount && item.summedLineAmount !== 0)
        );
        console.log('Filtered Data:', filteredData);

        const colorPalette = getColorPalette(filteredData.length);

        const transformedData = {
          labels: filteredData.map((item) =>
            xAxisFilter === 'organization'
              ? item.organization$_identifier
              : item.businessPartner$_identifier
          ),
          datasets: [
            {
              label:
                yAxisFilter === 'grandTotalAmount'
                  ? 'Grand Total Amount'
                  : 'Summed Line Amount',
              data: filteredData.map((item) =>
                yAxisFilter === 'grandTotalAmount'
                  ? item.grandTotalAmount
                  : item.summedLineAmount
              ),
              backgroundColor: colorPalette,
            },
          ],
        };

        setChartData({ transformedData, rawData: filteredData });
      } catch (err) {
        setError('Failed to fetch chart data.');
      }
    };

    getData();
  }, [fromDate, toDate, xAxisFilter, yAxisFilter]);

  // Filter the top data to exclude 0 amounts
  const getTopData = (data) => {
    const filteredData = data.filter((item) => {
      const grandTotalRounded = roundAmount(item.grandTotalAmount);
      const summedLineRounded = roundAmount(item.summedLineAmount);

      return (
        (grandTotalRounded > 0.999 && item.grandTotalAmount !== 0) ||
        (summedLineRounded > 0.999 && item.summedLineAmount !== 0)
      );
    });

    const sortedData = [...filteredData].sort((a, b) => {
      const valueA =
        yAxisFilter === 'grandTotalAmount' ? a.grandTotalAmount : a.summedLineAmount;
      const valueB =
        yAxisFilter === 'grandTotalAmount' ? b.grandTotalAmount : b.summedLineAmount;
      return valueB - valueA;
    });

    return sortedData.slice(0, topCount);
  };



  // Define chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const value = context.raw || 0;
            return `Value: ${value.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
      },
      y: {
        title: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return value.toLocaleString(); // Format y-axis ticks
          },
        },
      },
    },
  };


  const renderTable = () => {
    if (!chartData) return null;

    const { rawData } = chartData;

    // Group by organization or business partner and sum the amounts
    const groupedData = rawData.reduce((acc, item) => {
      const key =
        xAxisFilter === 'organization'
          ? item.organization$_identifier
          : item.businessPartner$_identifier;
      if (!acc[key]) {
        acc[key] = {
          key,
          grandTotalAmount: 0,
          summedLineAmount: 0,
        };
      }
      acc[key].grandTotalAmount += item.grandTotalAmount > 0 ? item.grandTotalAmount : 0;
      acc[key].summedLineAmount += item.summedLineAmount > 0 ? item.summedLineAmount : 0;
      return acc;
    }, {});

    // Convert the grouped data object into an array
    const groupedArray = Object.values(groupedData);

    // Get top N data based on user input
    const topData = getTopData(groupedArray);
    console.log('Grouped Data:', groupedData);
    console.log('Top Data:', topData);


    return (
      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>Sr</th>
              <th>{xAxisFilter === 'organization' ? 'Organization' : 'Business Partner'}</th>
              <th>Grand Total Amount</th>
              <th>Summed Line Amount</th>
            </tr>
          </thead>
          <tbody>
            {topData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.key}</td>
                <td>{roundAmount(item.grandTotalAmount)}</td>
                <td>{roundAmount(item.summedLineAmount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  const renderChart = () => {
    if (!chartData) return null;

    // Use the same topData used in the table
    const { rawData } = chartData;
    const groupedData = rawData.reduce((acc, item) => {
      const key =
        xAxisFilter === 'organization'
          ? item.organization$_identifier
          : item.businessPartner$_identifier;
      if (!acc[key]) {
        acc[key] = {
          key,
          grandTotalAmount: 0,
          summedLineAmount: 0,
        };
      }
      acc[key].grandTotalAmount += item.grandTotalAmount > 0 ? item.grandTotalAmount : 0;
      acc[key].summedLineAmount += item.summedLineAmount > 0 ? item.summedLineAmount : 0;
      return acc;
    }, {});

    const groupedArray = Object.values(groupedData);
    const topData = getTopData(groupedArray);

    // Transform the topData for the chart
    const colorPalette = getColorPalette(topData.length);
    const transformedData = {
      labels: topData.map((item) => item.key),
      datasets: [
        {
          label:
            yAxisFilter === 'grandTotalAmount'
              ? 'Grand Total Amount'
              : 'Summed Line Amount',
          data: topData.map((item) =>
            yAxisFilter === 'grandTotalAmount'
              ? item.grandTotalAmount
              : item.summedLineAmount
          ),
          backgroundColor: colorPalette,
        },
      ],
    };

    return (
      <div className="chart-container">
        {(() => {
          switch (chartType) {
            case 'Line':
              return <Line data={transformedData} options={options} />;
            case 'Pie':
              return <Pie data={transformedData} options={options} />;
            case 'Doughnut':
              return <Doughnut data={transformedData} options={options} />;
            case 'PolarArea':
              return <PolarArea data={transformedData} options={options} />;
            default:
              return <Bar data={transformedData} options={options} />;
          }
        })()}
      </div>
    );
  };

  return (
    <div className="dashboard">
      <DatePicker
        fromDate={fromDate}
        toDate={toDate}
        onFromDateChange={setFromDate}
        onToDateChange={setToDate}
      />
      <div
        className="top-count-controls"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          marginBottom: "10px",

        }}
      >
        <label
          htmlFor="top-count"
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          Top:
        </label>
        <input
          type="number"
          id="top-count"
          value={topCount}
          onChange={(e) => setTopCount(Number(e.target.value))}
          min="1"
          max="10000"
          style={{
            padding: "8px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            fontSize: "14px",
            width: "100px",
          }}
        />
      </div>

      {error ? (
        <div className="error">{error}</div>
      ) : !chartData ? (
        <div className="loading">Loading...</div>
      ) : (
        <div className="main-body">
          {renderTable()}
          <div className="controls ">
            <FilterControls
              xAxisFilter={xAxisFilter}
              onXAxisFilterChange={setXAxisFilter}
              yAxisFilter={yAxisFilter}
              onYAxisFilterChange={setYAxisFilter}
            />
            <select
              value={chartType}
              onChange={(e) => setChartType(e.target.value)}
              className="controls select"
            >
              <option value="Bar">Bar Chart</option>
              <option value="Line">Line Chart</option>
              <option value="Pie">Pie Chart</option>
              <option value="Doughnut">Doughnut Chart</option>
              <option value="PolarArea">Polar Area Chart</option>
            </select>
          </div>
          {renderChart()}
        </div>

      )}
    </div>
  );
};

export default BarChart;