import axios from 'axios';
import { API_CONFIG, ENDPOINTS } from './constants';

const api = axios.create({
  baseURL: API_CONFIG.BASE_URL,
  auth: {
    username: API_CONFIG.AUTH.USERNAME,
    password: API_CONFIG.AUTH.PASSWORD,
  },
});

export const fetchChartData = async (fromDate, toDate) => {
  try {
    const params = {
      _where: `invoiceDate>='${fromDate}' and invoiceDate<='${toDate}' and documentAction = 'CO'`,
      _selectedProperties: 'organization,businessPartner,summedLineAmount,grandTotalAmount,userContact',
    };

    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${API_CONFIG.BASE_URL}${ENDPOINTS.INVOICE}?${queryString}`;
    console.log('Constructed API URL:', fullUrl);

    const response = await api.get(ENDPOINTS.INVOICE, { params });

    return response.data.response.data;
  } catch (error) {
    console.error('Error fetching invoice data:', error.message || error);
    throw error;
  }
};



  // export const fetchChartData = async (fromDate, toDate) => {
  //   try {
  //     const params = {
  //       _where: `invoiceDate>='${fromDate}' and invoiceDate<='${toDate}' and documentAction = 'CO'`,
  //       _selectedProperties: 'organization,businessPartner,summedLineAmount,grandTotalAmount,userContact',
  //     };
  
  //     // Construct the full URL for logging
  //     const queryString = new URLSearchParams(params).toString();
  //     const fullUrl = `${api.defaults.baseURL}/org.openbravo.service.json.jsonrest/Invoice?${queryString}`;
  //     console.log('Constructed API URL:', fullUrl);
  
  //     // Make the API request
  //     const response = await api.get('/org.openbravo.service.json.jsonrest/Invoice', { params });
  
  //     return response.data.response.data;
  //   } catch (error) {
  //     console.error('Error fetching invoice data:', error.message || error);
  //     throw error;
  //   }
  // };
  