import React from 'react';
import '../styles.css';
import BarChart from './charts/BarChart'; 

const SalesDashboard = () => {
  return (
    <div className="sales-dashboard">
      <BarChart />
    </div>
  );
};

export default SalesDashboard;
