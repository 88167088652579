import React from 'react';

const DatePicker = ({ fromDate, toDate, onFromDateChange, onToDateChange }) => {
  const handleFromDateChange = (e) => {
    const newFromDate = new Date(e.target.value);
    onFromDateChange(newFromDate);
  };

  const handleToDateChange = (e) => {
    const newToDate = new Date(e.target.value);
    onToDateChange(newToDate);
  };

  const styles = {
    container: {
      display: "flex",
      justifyContent: 'flex-start', // Align items closely
      alignItems: 'center',
      gap: '20px', // Control the spacing between date pickers
      padding: '5px 0',
    },
    label: {
      marginRight: '10px',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    input: {
      padding: '8px 10px',
      borderRadius: '5px',
      border: '1px solid #ccc',
      fontSize: '14px',
      width: '200px',
    },
    dateContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <div style={styles.container}>
        <div style={styles.dateContainer}>
          <label htmlFor="from-date-picker" style={styles.label}>
            From Date:
          </label>
          <input
            id="from-date-picker"
            type="date"
            value={fromDate.toISOString().split('T')[0]}
            onChange={handleFromDateChange}
            style={styles.input}
          />
        </div>
        <div style={styles.dateContainer}>
          <label htmlFor="to-date-picker" style={styles.label}>
            To Date:
          </label>
          <input
            id="to-date-picker"
            type="date"
            value={toDate.toISOString().split('T')[0]}
            onChange={handleToDateChange}
            style={styles.input}
          />
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
