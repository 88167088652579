import React from 'react';

const FilterControls = ({
  xAxisFilter,
  onXAxisFilterChange,
  yAxisFilter,
  onYAxisFilterChange,
}) => {
  return (
    <div
      className="filter-controls"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
        marginBottom: '5px',
      }}
    >
      {/* X-Axis Filter */}
      <div className="x-axis-filter">
        <label>
          X-Axis:
          <select
            value={xAxisFilter}
            onChange={(e) => onXAxisFilterChange(e.target.value)}
            className="axis-selector"
            style={{
              marginLeft: '10px',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          >
            <option value="organization">Organization</option>
            <option value="businessPartner">Business Partner</option>
          </select>
        </label>
      </div>

      {/* Y-Axis Filter */}
      <div className="y-axis-filter">
        <label>
          Y-Axis:
          <select
            value={yAxisFilter}
            onChange={(e) => onYAxisFilterChange(e.target.value)}
            className="axis-selector"
            style={{
              marginLeft: '10px',
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          >
            <option value="grandTotalAmount">Grand Total Amount</option>
            <option value="summedLineAmount">Summed Line Amount</option>
          </select>
        </label>
      </div>
    </div>
  );
};

export default FilterControls;
